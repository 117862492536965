import type { ReactElement } from 'react';

export type TableRowRenderFn = () => ReactElement;
export type TableProps = {
  columns?: {
    label: string;
    mobileLabel?: string;
  }[];
  rows?: (string | TableRowRenderFn)[][];
  containerClasses?: string;
};

export function Table({ columns = [], rows = [], containerClasses }: TableProps) {
  return (
    <div className={`block ${containerClasses}`}>
      <div className="w-full overflow-x-auto">
        <table className="mt-4 items-center bg-transparent w-full border border-blue-100 rounded-md p-6 bg-white table-auto xl:table-fixed">
          <thead className="border bg-blue-50 text-xs uppercase font-semibold text-left whitespace-nowrap">
            <tr>
              {columns?.map((col) => (
                <th key={col.label} className="px-3 md:px-4 py-3">
                  <span className={col.mobileLabel && 'hidden lg:block'}>{col.label}</span>
                  {col.mobileLabel && <span className="block lg:hidden">{col.mobileLabel}</span>}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows?.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`} className="align-middle text-xs whitespace-nowrap text-left hover:bg-gray-50">
                {row.map((rowColumn, colIndex) => (
                  <td key={`${colIndex}-${rowIndex}`} className="px-3 md:px-4 py-3">
                    {typeof rowColumn === 'function' ? rowColumn() : rowColumn}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
