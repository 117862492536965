import clsx from 'clsx';
import { useRef } from 'react';

import type { PropsWithChildren } from 'react';

export type FormInputCustomProps = {
  id: string;
  containerClassName?: string;
  label?: string;
  description?: string;
  labelClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  type?: string;
  hasErrors?: boolean;
};

export function FormInput({
  customProps,
  inputProps,
  children,
}: PropsWithChildren<{ customProps: FormInputCustomProps; inputProps: any }>) {
  const {
    id,
    containerClassName = '',
    label = '',
    description,
    labelClassName = '',
    inputClassName = '',
    placeholder = '',
    type = 'text',
    hasErrors = false,
  } = customProps;
  const inputRef = useRef<HTMLInputElement>();

  return (
    <div className={containerClassName} onClick={() => inputRef.current?.focus()}>
      <label htmlFor={id} className={`block text-gray-700 text-sm font-bold mb-2 ${labelClassName}`}>
        {label}
      </label>
      <div className={clsx({ relative: description })}>
        <input
          ref={inputRef}
          type={type}
          className={clsx(
            'block w-full text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none border focus:border-gray-500 ',
            inputClassName,
            {
              'border-gray-200': !hasErrors,
              'border-red-400': hasErrors,
              'bg-white': !inputProps.disabled,
              'opacity-60 bg-transparent': inputProps.disabled,
            }
          )}
          id={id}
          placeholder={placeholder}
          {...inputProps}
        />
        {description && (
          <div className="absolute inset-y-px right-px flex items-center px-4 bg-gray-100">{description}</div>
        )}
        {children}
      </div>
    </div>
  );
}
